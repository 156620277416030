import {
  AgnosticLineItem,
  AgnosticOrder,
  AgnosticPriceValue,
  AgnosticProduct,
  AgnosticSearchParams,
  AgnosticVariant,
  AgnosticWishlistLineItem
} from 'shared/types'

declare global {
  interface Window {
    dataLayer?: any[]
    google_tag_manager?: any
  }
}
type GTMPageType =
  | 'home'
  | 'clp'
  | 'plp'
  | 'searchresult'
  | 'nosearchresult'
  | 'pdp'
  | 'cart'
  | 'checkout'
  | 'orderconfirmation'
  | 'account'
  | 'content'
  | 'storelocator'
  | 'error'
  | 'other'
  | 'wishlist'

type GTMProductsList =
  | 'Search Results'
  | 'Search Suggestions'
  | 'Products Listings'
  | 'Shopping Cart'
  | 'PDP'
  | 'Wishlist'
  | 'Products Carousel'

const CHECKOUT_STEPS = {
  'Shopping Cart': 1,
  Shipping: 2,
  Payment: 3,
  'Order Review': 4,
  Confirmation: 5
}

const getProductImpressionPrice = (prices: AgnosticPriceValue[]) => getProductPriceRange(prices).min

const getVariantProperties = (
  product: AgnosticProduct | AgnosticLineItem | AgnosticWishlistLineItem,
  params: { color?: string; size?: string } = {}
): string | undefined => {
  const paramsProperties = [params.color, params.size].filter(Boolean)
  if (paramsProperties.length) {
    return paramsProperties.join('|')
  }

  if (Array.isArray(product.properties)) {
    return product.properties.map((property) => property.key).join('|')
  }

  if (isWishlistLineItem(product) && Array.isArray(product.selectedProperties)) {
    return product.selectedProperties.map((property) => property.key).join('|')
  }

  return undefined
}

function isLineItem(
  product: AgnosticProduct | AgnosticLineItem | AgnosticWishlistLineItem
): product is AgnosticLineItem {
  return typeof (product as any).productId !== 'undefined'
}

function isWishlistLineItem(
  product: AgnosticProduct | AgnosticLineItem | AgnosticWishlistLineItem
): product is AgnosticWishlistLineItem {
  return typeof (product as any).itemId !== 'undefined'
}

const getProductsPayload = (
  products: readonly AgnosticProduct[] | readonly AgnosticLineItem[] | readonly AgnosticWishlistLineItem[],
  params: {
    color?: string
    size?: string
    list?: string
    calcPosition?: boolean
    position?: number
    quantity?: number
  } = {}
) => {
  return products.map((product: AgnosticProduct | AgnosticLineItem | AgnosticWishlistLineItem, index) => {
    const payload: Record<string, any> = {
      name: product.name,
      id: isLineItem(product) ? product.productId : product.id,
      price:
        'price' in product
          ? getProductImpressionPrice([product.price])
          : getProductImpressionPrice(getProductPrices(product))
    }

    if ('quantity' in product) {
      payload.quantity = product.quantity
    } else if (params.quantity) {
      payload.quantity = params.quantity
    }

    const variantProperties = getVariantProperties(product, params)
    if (variantProperties) {
      payload.variant = variantProperties
    }

    if (params.list) {
      payload.list = params.list
    }

    if (params.position) {
      payload.position = params.position
    } else if (params.calcPosition) {
      payload.position = index + 1
    }

    let categoryName

    if (isLineItem(product)) {
      categoryName = product.categoryName
    } else if ('categories' in product) {
      categoryName = product.categories.at(-1)?.label
    }

    if (categoryName) {
      payload.category = categoryName
    }

    const brandValue = isLineItem(product) ? product.productProperties?.brand?.value : product.properties?.brand?.value
    if (brandValue) {
      payload.brand = brandValue
    }

    return payload
  })
}

const initTagManagerScripts = (options: { tagManagerId: string }) => {
  const { tagManagerId } = options

  useHead({
    script: [
      {
        key: 'gtm-script',
        // eslint-disable-next-line max-len
        innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${tagManagerId}');`
      },
      {
        key: 'gtm-script-datalayer',
        innerHTML: 'window.dataLayer = window.dataLayer || [];'
      }
    ],
    noscript: [
      {
        key: 'gtm-noscript',
        // eslint-disable-next-line max-len
        innerHTML: `<iframe src="https://www.googletagmanager.com/ns.html?id=${tagManagerId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        tagPosition: 'bodyOpen'
      }
    ]
  })
}

export function useTagManager() {
  const nuxtApp = useNuxtApp()
  const isGtmInjected = useState('isGtmInjected', () => false)
  const { load } = useSession()

  const { $preferences, $analytics, $i18n } = nuxtApp
  const { locale, locales } = $i18n

  const getLocaleProperties = () => {
    const localeDetails = locales.value.find(({ code }) => code === locale.value)
    const isoLocale = localeDetails?.iso || locale
    const currencyCode = localeDetails?.currency

    return { locale: isoLocale, currencyCode }
  }

  const getAffiliation = () => {
    return $preferences.tagManagerAffiliation
  }

  const report = (...args: unknown[]): void => {
    const { tagManagerId, tagManagerEnabled } = $preferences

    if (!tagManagerEnabled || !tagManagerId) {
      return
    }

    if (process.server) {
      if (!isGtmInjected.value) {
        initTagManagerScripts({ tagManagerId })
        isGtmInjected.value = true
      }
      // report events only on client to avoid duplication
      // useHead({
      //   script: [
      //     {
      //       innerHTML: `window.dataLayer.push.apply(window.dataLayer, ${JSON.stringify(args)});`
      //     }
      //   ]
      // })
    } else if (window.dataLayer) {
      window.dataLayer.push(...args)
    }
  }

  const reportPageView = (pageType: GTMPageType) => {
    const { currencyCode, locale: isoLocale } = getLocaleProperties()

    if (process.client) {
      load().then((session) => {
        report({
          event: 'virtualPageview',
          // eslint-disable-next-line camelcase
          user_id: session?.customer?.uuid,
          loginStatus: session?.isRegistered ? 'registered' : 'guest',
          currencyCode,
          locale: isoLocale,
          pageType
        })
      })
      const promoSelector = '.js-promo-impression'

      waitUntil(() => document.querySelector(promoSelector)).then(() => {
        const promotions = Array.from(document.querySelectorAll(promoSelector)).map((impressionEl) => {
          const dataset = (impressionEl as HTMLElement).dataset
          return { id: dataset.promoId, name: dataset.promoName }
        })

        if (promotions.length) {
          report({
            event: 'virtualPageview',
            ecommerce: { promoView: { promotions } }
          })
        }
      })
    }
  }

  const reportProductImpressions = (
    products: readonly AgnosticProduct[] | readonly AgnosticLineItem[] | readonly AgnosticWishlistLineItem[],
    list: GTMProductsList,
    params: { color?: string; size?: string } = {}
  ) => {
    const { currencyCode } = getLocaleProperties()
    const impressions = getProductsPayload(products, { ...params, list, calcPosition: true })

    while (impressions.length) {
      report({
        event: 'virtualPageview',
        ecommerce: {
          currencyCode,
          impressions: impressions.splice(0, 20)
        }
      })
    }
  }

  const reportProductDetailsView = (
    product: AgnosticProduct | undefined,
    params: { color?: string; size?: string } = {}
  ) => {
    if (!product) {
      return
    }

    $analytics.publish({ name: 'ProductDetailsView', payload: { product } })

    const { currencyCode } = getLocaleProperties()

    report({
      event: 'virtualPageview',
      ecommerce: {
        currencyCode,
        detail: {
          actionField: { list: 'Product Details Page' },
          products: getProductsPayload([product], params)
        }
      }
    })
  }

  const reportProductClick = (
    product: AgnosticProduct | AgnosticLineItem | AgnosticWishlistLineItem,
    list: GTMProductsList,
    params: { position?: number } = {}
  ) => {
    report({
      event: 'productClick',
      ecommerce: {
        click: {
          actionField: { list },
          products: getProductsPayload(
            [product] as AgnosticProduct[] | AgnosticLineItem[] | AgnosticWishlistLineItem[],
            params
          )
        }
      }
    })
  }

  const reportBrowsingProductImpressions = (
    products: AgnosticProduct[] | AgnosticLineItem[],
    list: GTMProductsList,
    params: { color?: string; size?: string } = {}
  ) => {
    const { currencyCode } = getLocaleProperties()
    const impressions = getProductsPayload(products, { ...params, list, calcPosition: true })

    while (impressions.length) {
      report({
        event: 'productImpressions',
        ecommerce: {
          currencyCode,
          impressions: impressions.splice(0, 20)
        }
      })
    }
  }

  const reportAddToCart = (
    product: AgnosticProduct,
    selectedVariant: AgnosticVariant,
    params: { quantity: number; color?: string; size?: string }
  ) => {
    const { currencyCode } = getLocaleProperties()

    $analytics.publish({ name: 'AddToCart', payload: { product } })

    report({
      event: 'addToCart',
      ecommerce: {
        currencyCode,
        add: {
          products: getProductsPayload([product], params).map((productItem) => {
            const productPayload: Record<string, any> = {
              ...productItem,
              price: getProductImpressionPrice([selectedVariant.price].filter(notEmpty))
            }

            if (product.properties.brand) {
              productPayload.brand = product.properties.brand.value
            }

            return productPayload
          })
        }
      }
    })
  }

  const reportRemoveFromCart = (product: AgnosticLineItem) => {
    const { currencyCode } = getLocaleProperties()

    report({
      event: 'removeFromCart',
      ecommerce: {
        currencyCode,
        remove: { products: getProductsPayload([product]) }
      }
    })
  }

  const reportCheckoutStep = (products: AgnosticLineItem[], step: keyof typeof CHECKOUT_STEPS, option?: string) => {
    report({
      event: 'checkout',
      ecommerce: {
        checkout: {
          actionField: { step: CHECKOUT_STEPS[step], option },
          products: getProductsPayload(products)
        }
      }
    })
  }

  const reportShippingSelected = (option: string) => {
    report({
      event: 'shippingSelected',
      ecommerce: {
        // eslint-disable-next-line camelcase
        checkout_option: { actionField: { step: CHECKOUT_STEPS.Shipping, option } }
      }
    })
  }

  const reportPaymentSelected = (option: string) => {
    report({
      event: 'paymentSelected',
      ecommerce: {
        // eslint-disable-next-line camelcase
        checkout_option: { actionField: { step: CHECKOUT_STEPS.Payment, option } }
      }
    })
  }

  const reportOrderReceiptPage = (order: AgnosticOrder) => {
    const actionField: Record<string, any> = {
      id: order.orderNumber,
      revenue: order.total.special || order.total.regular,
      shipping: order.selectedShippingMethod?.name,
      tax: order.summaries.find((summary) => summary.type === 'tax')?.price.regular
    }

    const couponCode = order.coupons[0]?.code
    if (couponCode) {
      actionField.coupon = couponCode
    }

    const affiliation = getAffiliation()
    if (affiliation) {
      actionField.affiliation = affiliation
    }

    report({
      event: 'virtualPageview',
      ecommerce: {
        purchase: {
          actionField,
          products: getProductsPayload(order.lineItems)
        }
      }
    })
  }

  const reportPromoClick = (data: { id: string; name: string }) => {
    report({
      event: 'promotionClick',
      ecommerce: {
        promoView: {
          promotions: [data]
        }
      }
    })
  }

  const reportFiltersSelected = (filters: { option: { label: string }; filterKey: string }[]) => {
    $analytics.publish({ name: 'FilterSelected', payload: { filters } })
  }

  const reportSearchResultViewed = (searchParams: AgnosticSearchParams) => {
    $analytics.publish({ name: 'FilterViewed', payload: { searchParams } })
  }

  return {
    reportPageView,
    reportProductImpressions,
    reportBrowsingProductImpressions,
    reportProductDetailsView,
    reportProductClick,
    reportAddToCart,
    reportRemoveFromCart,
    reportCheckoutStep,
    reportShippingSelected,
    reportPaymentSelected,
    reportOrderReceiptPage,
    reportPromoClick,
    reportSearchResultViewed,
    reportFiltersSelected
  }
}
